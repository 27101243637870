<template>
  <div
    v-if="attachmentArray !== null && attachmentArray.length > 0"
    class="attachments"
  >
    <h2 class="attachments__title">Attachments</h2>
    <hr />
    <b-button-group class="attachments__button-group">
      <div v-for="(attachment, index) in attachmentArray" :key="index">
        <a :href="attachment" target="_blanck">
          <b-button
            ref="button"
            variant="primary"
            size="sm"
            aria-label="Click here to download the file"
            class="attachments__button"
          >
            Attachment {{ index + 1 }}
            <b-icon
              icon="box-arrow-up-right"
              style="font-size: .5rem"
            ></b-icon></b-button
        ></a>
      </div>
    </b-button-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Attachments",
  data() {
    return {
      attachmentArray: []
    };
  },
  props: {
    infoPrincipalView: String
  },
  computed: {
    ...mapState("emailDetails", ["attachments"]),
    ...mapState("incompleteHardfailGlt", ["attachmentsHardFail"])
  },
  created() {
    if (this.infoPrincipalView == "sticky") {
      this.attachmentArray = this.attachments;
    } else {
      this.attachmentArray = this.attachmentsHardFail;
    }
  }
};
</script>

<style scoped lang="scss">
.attachments {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    padding: 10px;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: #757575;
  }
  &__button-group {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__button {
    margin: 3px;
    padding: 5px 5px;
    color: white;
    background-color: #00557e;
    border: none;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px,
      rgb(0 0 0 / 19%) 0px 6px 20px 0px;
    &:hover {
      background-color: var(--button-hover-color);
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  hr {
    width: 90%;
  }
}
</style>
