import { render, staticRenderFns } from "./CustomCheckbox.vue?vue&type=template&id=25252972&scoped=true&"
import script from "./CustomCheckbox.vue?vue&type=script&lang=js&"
export * from "./CustomCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./CustomCheckbox.vue?vue&type=style&index=0&id=25252972&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25252972",
  null
  
)

export default component.exports